import styled from "styled-components"
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../../theme/GlobalStyles";

export const ModalMenuContainer = styled.div`
    position: relative;

    .ContainerActionButtonsMenuMobil{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
       
    }
    .ContainerItemsMenuMobil{
        margin-top: 10%;
    }
    .ContainerCloseActionButtonMobil{
        cursor: pointer;
    }
    .ContainerItemMenuMobil{
        margin-top: 20px;
        margin-bottom: 10px;
        cursor: pointer;
        border-bottom: 1px solid #EFEFEF;
    }
    .ContainerItemMenuMobil p{
        color: #290C56;
        font-family: 'Montserrat-SemiBold', sans-serif;
        font-weight: bold;
        padding-bottom: 5px;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .ContainerDropDown{
        background: #fff;
    }

    .ContainerDropDown ul{
        list-style: none;
        margin-left: 7%;
        padding-bottom: 30px;
        padding-top: 10px;
    }
    .ContainerDropDown > ul > li {
        color: #290C56;
        font-family: 'Montserrat-SemiBold', sans-serif;
        font-weight: bold;
        font-size: 14px;
        padding-top:5px;
        padding-bottom: 4px;
    }
    .ContainerItemMenuMobilDown{
       display: flex;
       justify-content: space-between
    }
    .ContainerItemMenuMobilDown p{
        color: #290C56;
        font-family: 'Montserrat-SemiBold', sans-serif;
        font-weight: bold;
        padding-bottom: 5px;
        font-size: 14px;
    }
    .ContainerIconsMenuDesk{
        display: flex;
        align-items: center;
    }
    .ContainerIconsMenuDesk p {
        margin-left: 10px;
    }
   
  // Mobil Frist
  @media screen and (min-width: 200px){
   
  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){

  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
 
    // DESK
}
@media screen and (min-width: 930px) and (orientation: landscape) {
  
}
`