import styled from "styled-components";
import { motion } from "framer-motion";
import GlobalStyles from "../../theme/GlobalStyles";
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
  z-index: 1;

  .containerLeftMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

  }
  .containerLeftMenu:before {
    content: "";
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 30%;
    background-color: rgb(155, 38, 144);
    opacity: 0.5;
    pointer-events: none;
    animation: pulse 3s infinite;
    z-index: -1;
  }
  .ContainerHerdezImgHeader{
    margin-left: 15px;
    margin-top: 10px;
  }
  // Mobil Frist
  @media screen and (min-width: 200px) {
    .ContainerLogoHeader img {
      width: ${props => props.isBackground === true ? '80%' :  "50%"} ;
     
    }
  }
  @media screen and (min-width: 490px) {
    .ContainerLogoHeader img {
      width: ${props => props.isBackground === true ? '70%' :  "30%"} ;
     
    }
  }

  @media screen and (min-width: 720px) {
    .ContainerLogoHeader img {
      width: ${props => props.isBackground === true ? '20%' :  "20%"} ;
    }
  }
  @media screen and (min-width: 900px) {
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }

  // animacion
  @keyframes pulse {
    0%,
    51%,
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;
export const StyledCircle = styled(motion.div)`
  width: 100px;
  height: 100px;
  background-color: blue;
  border-radius: 50%;
`

export const HeaderMenu = styled(motion.nav)`
 width: 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* position: sticky; */
  transition: width 0.5s ease;
  overflow-x: hidden; // Desactiva el scroll horizontal
  text-align: center;
  align-items: center;
  z-index: 1;
  padding-top: 0px;
  background: ${props => props.isBackground === true ? '#534b4b42' :  '#fff'};
  /* position: fixed; */
  overflow: visible;
  position: relative;
  &:before {
              content: "";
              position: absolute;
              height: 20px;
              width: 40px;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              z-index: -1;
              backdrop-filter: blur(18px);
            }

  .ContainerCloseImg{
    cursor: pointer;
    margin-left: 20px;
  }
  .ContainerHederLogoMenu{
    margin-right: 30px;
    padding-top: 15px;
  }
  .ContainerItemMenuDesk{
    cursor: pointer;
    width: 20%;
  }
  
  .ContainerItemMenuDesk p{
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    text-align: center;
    color: ${props => props.isBackground === true ? '#ffff' :  ' #290C56'};
  }
  .ContainerItemMenuDeskDown{
    width: 20%;
    position: relative;
    display: flex;
    margin-left: 30px;
  }
  .ContainerItemMenuDeskDown p{
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    text-align: center;
    color: ${props => props.isBackground === true ? '#ffff' :  ' #290C56'};
  }
  .ContainerDownDeskMenu{
    position: absolute;
    top: 20px;
    left: 0px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 20%;
   
  }

  .custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  width: 100%;
  padding: 10px;
  appearance: none;
  border: none;
  border-radius: 5px;
  outline: none;
  font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    color: ${props => props.isBackground === true ? '#ffff' :  ' #290C56'};
    font-size:1rem;
  background-color: #fff; /* Agrega tu color de fondo deseado */
}
.custom-select option {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 0px 0px 15px 15px;
  opacity: 0.8;
  overflow: hidden;
}

.custom-select option::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(5px);
  z-index: -1;
}

.custom-select img {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}
    // Mobil Frist
    @media screen and (min-width: 200px) {

  }
  @media screen and (min-width: 720px) {

  }
  @media screen and (min-width: 900px) {
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
export const CustomSelect = styled.div`
  position: relative;
  width: 80%;
  max-width: 276px;
  z-index: 1;
`;

export const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
 
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  color: ${props => props.isBackground === true ? '#ffff' :  ' #290C56'};
  font-size: 1rem;
width: 60%;
`;

export const OptionList = styled(motion.div)`
  position: relative;
  &:before {
              content: "";
              position: absolute;
              height: 20px;
              width: 40px;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              z-index: -1;
              backdrop-filter: blur(18px);
            }
  .ContainerUl{
    list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background: ${props => props.isBackground === true ? '#3f393963' :  ' #ffffff4f'} ;
      overflow: hidden;
  }
  .ContainerUl::before{
    content: "";
              position: absolute;
              height: 20px;
              width: 40px;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              z-index: -1;
              backdrop-filter: blur(18px);
  }
  
`;

export const OptionItem = styled(motion.li)`
  padding: 10px;
 
  cursor: pointer;
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  color:  ${props => props.isBackground === true ? '#ffff' :  ' #290C56'};
  font-size: 1rem;
`;

// export const StyledCircle = styled(motion.div)`
//   width: 100px;
//   height: 100px;
//   background-color: blue;
//   border-radius: 50%;
// `;