// useAxiosGet.js
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { CosmoContext } from '../context/CosmoContext';

const useAxios = () => {
  const [data, setData] = useState(null);
  const [loadingApi, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {HandlerRutafooter,ActualRouter} = useContext(CosmoContext)
  

  const LoadData = async (url) => {
    try {
      const response = await axios.get(url);
      setData(response.data);
       HandlerRutafooter(response.data.data.ruta)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {LoadData, data, loadingApi, error };
};

export default useAxios;
