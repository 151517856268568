import React, { useState } from "react";

const useModals = () => {
  // MODAL MENUHAMBURGUESA
  const [ModalHamburguesa, setModalHamburguesa] = useState(false);
  const [MenuDesk, setMenuDesk] = useState(false);
  const [ModalPrime, setModalPrime] = useState(false);
  const [ModalManualPrime, setModalManual] = useState(false);
  const [ModalMAtrizPrime, setModalMAtrizPrime] = useState(false);
  const [ModalMarcoPrime, setModalMarcoPrime] = useState(false);
  const [ModalSitemap, setModalSitemap] = useState(false)
  const [ModalImages, setModalImages] = useState(false)

  const OpenModalImages = () => {
    setModalImages(true)
  }
  const CloseModalImages = () => {
    setModalImages(false)
  }

  const HandlerOpenSitemap = () => {
    setModalSitemap(true)
  }
  const HandlerCloseSitemap = () => {
    setModalSitemap(false)
  }

  const OpenModalMarcoPrime = () => {
    setModalMarcoPrime(true)
  }
const CloseModalMarcoPrime = () =>{
  setModalMarcoPrime(false)
}
  const OpenModalMatriz = () => {
    setModalMAtrizPrime(true);
  };
  const CloseModalMatriz = () => {
    setModalMAtrizPrime(false);
  };
  const OpenModalManual = () => {
    setModalManual(true);
  };
  const CloseModalManual = () => {
    setModalManual(false);
  };

  const OpenModalPrime = () => {
    setModalPrime(true);
  };
  const CloseModalPrime = () => {
    setModalPrime(false);
  };

  const openModal = () => {
    setModalHamburguesa(true);
  };

  const closeModal = () => {
    setModalHamburguesa(false);
  };
  // Menu Desk
  const handleropenMenuDesk = () => {
    setMenuDesk(true);
  };
  const CloseModalOpenDesk = () => {
    setMenuDesk(false);
  };
  return {
    ModalHamburguesa,
    openModal,
    closeModal,
    MenuDesk,
    handleropenMenuDesk,
    CloseModalOpenDesk,
    ModalPrime,
    OpenModalPrime,
    CloseModalPrime,
    ModalManualPrime,
    OpenModalManual,
    CloseModalManual,
    ModalMAtrizPrime,
    OpenModalMatriz,
    CloseModalMatriz,
    ModalMarcoPrime,
OpenModalMarcoPrime,
CloseModalMarcoPrime,
ModalSitemap,
HandlerOpenSitemap,
HandlerCloseSitemap,
ModalImages,
OpenModalImages,
CloseModalImages
  };
};

export default useModals;
