import styled from "styled-components"
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const FooterContainerDesk = styled.div`
    background: #fff;
    position: relative;
    bottom: 0px;
    width: 100%;
    height:40px;

    .tooltip {
  visibility: hidden;
  top: -24px;
  right: -250px;
  width: 306px;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  color: #3F348B;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip p {
  margin-left: 10px;
}

.CircleStep:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.ContainerFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin: auto;
}
.CircleStartt{
    border-radius: 50%;
    height:25px;
    width: 25px;
    cursor: pointer;
}
.ContainerItemsFooter{
    display:flex;
    flex-direction: row;
    margin-top: 10px;
    position: relative;
    z-index: 1;
}
.ContainerNameItemFooter{
    display: flex;
    margin-top: -25px;
    align-items: center;
    width: 100%;
}
.ContainerNameItemFooter span{
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-weight: bold;
    font-size: 22px;
    margin-right: 5px;
}
.ContainerNameItemFooter p{
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-weight: bold;
    font-size: 13px;
    margin-right: 5px;
}
.LineGradientFooter{
    position: absolute;
    width: 97%;
    top:20px;
    left:3%;
    height:5px;
    background: transparent linear-gradient(105deg, #FFFFFF 0%, #B9DB10 4%, #219348 10%, #00A99D 17%, #3565AC 60%, #3F348B 70%, #9B2690 85%, #BE0C13 100%) 0% 0% no-repeat padding-box;
}
.ContainerStepps{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
}
.ContainerInfoFooter{
    display: flex;
    flex-direction: row;
    margin-top: 0px;
}
.ContainerInfoFooter p{
    margin-top: 2px;
}
.CircleStep{
    border-radius: 50%;
    height:15px;
    width: 15px;
    border-width: 2px;
    border-style: solid;
    margin-top: 5px;
    position: relative;
    cursor: pointer;
}
.CircleStep:hover{
    
/*        
       margin-top: -30px;
       width: 30px;
        height: 30px;
        background-color: red !important; */
    }
    .tooltipLast {
  visibility: hidden;
  top: -24px;
    right: -130px;
    width: 170px;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  color: #3F348B;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}
    .gateContianerBody{
        position: relative;
    }
.gateContianer{
    cursor: pointer;
    animation: pulso 2s infinite;
}
.gateContianerBody:hover .tooltipLast {
  visibility: visible;
  opacity: 1;
}
.gateContianerCheck{
    cursor: pointer;
    margin-top: -2px;
    padding: 10px;
    border-radius: 50%;
    height: 6px;
    width: 6px;
    position: relative;
    /* animation: pulso 2s infinite; */
    /* border-width: 3px; */
    /* border-style: solid; */
   
}
.gateContianerCheck:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.gateContianerCheck img{
    margin-top: -7px;
    margin-left: -5px;
}

// animacion
@keyframes pulso {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  // Mobil Frist
  @media screen and (min-width: 200px){
   
  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){

  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
 
    // DESK
}
@media screen and (min-width: 930px) and (orientation: landscape) {
  
}
`
export const FooterContainerMovil = styled.div`
 
    position: absolute;
    top: 20%;
    width: 10%;
    overflow: hidden;
    left: 0px;
    height:70vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 5;
.ContainerFooterMovil{
    display: flex;
    flex-direction: column;
    width: 100%;
  
    height: 70vh;
    margin-left: 10px;
}
.CircleStarttMobil{
    border-radius: 50%;
    height:25px;
    width: 25px;
    z-index: 999;
    cursor: pointer;
}
.ContainerItemsFooterMobil{
    display:flex;
    flex-direction: column;
    margin-top: 10px;
    position: relative;
    z-index: 1;
}
.ContainerNameItemFooter{
    display: flex;
    margin-top: -25px;
    align-items: center;
    width: 100%;
}
.ContainerNameItemFooter span{
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-weight: bold;
    font-size: 22px;
    margin-right: 5px;
}
.ContainerNameItemFooter p{
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-weight: bold;
    font-size: 13px;
    margin-right: 5px;
}
.LineGradientFooterMobil{
    position: absolute;
    width: 11%;
    height: 100% !important;
    height: 100vh;
    top: 20px;
    left: 37px;
    left: 50%;
    height: 5px;
    background: transparent linear-gradient(105deg, #FFFFFF 0%, #B9DB10 4%, #219348 10%, #00A99D 17%, #3565AC 60%, #3F348B 70%, #9B2690 85%, #BE0C13 100%) 0% 0% no-repeat padding-box;
}
.ContainerSteppsMovbil{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 33px;
    margin-left: 2px;
}
.ContainerInfoFooterMobil{
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-left: 4px;
}
.ContainerInfoFooter p{
    margin-top: 2px;
}
.CircleStepMobil{
    border-radius: 50%;
    height:10px;
    width: 10px;
    border-width: 2px;
    border-style: solid;
    margin-top: 5px;
    margin-left: 3px;
}
.gateContianer{
    animation: pulso 2s infinite;
}
.gateContianerCheck{
    
    margin-top: -2px;
    padding: 8px;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    
    /* border-width: 2px;
    border-style: solid; */

    margin-left: -3px;
    margin-bottom: 10px;
}
.gateContianerCheck img{
    margin-top: -10px;
    margin-left: -6px;
}

// animacion
@keyframes pulso {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  // Mobil Frist
  @media screen and (min-width: 200px){
    .CircleStarttMobil{
        color: #FFFFFF;
    }
    .ContainerNunSteep{
        margin-top: 5px;
    }
    .CircleStepMobil{
        margin-left: 0px;
    }
  }
  @media screen and (max-width: 630px) {
    .ContainerFooterMovil{
        margin-left: 23px !important;
    }
}
@media screen and (max-width: 688px) {
    .ContainerFooterMovil{
        margin-left: 25px !important;
    }
}
@media screen and (max-width: 649px) {
    .ContainerFooterMovil{
        margin-left: 23px !important;
    }
}
@media screen and (max-width: 600px) {
    .ContainerFooterMovil{
        margin-left: 20px !important;
    }
}
@media screen and (max-width: 560px) {
    .ContainerFooterMovil{
        margin-left: 18px !important;
    }
}
@media screen and (max-width: 520px) {
    .ContainerFooterMovil{
        margin-left: 16px !important;
    }
}
@media screen and (max-width: 475px) {
    .ContainerFooterMovil{
        margin-left: 10px !important;
    }
}
  @media screen and (min-width: 500px) {
    .ContainerFooterMovil{
        margin-left: 20px;
    }
}
@media screen and (min-width: 689px) {
    .ContainerFooterMovil{
        margin-left: 25px;
    }
}
  @media screen and (min-width: 720px){
    .ContainerFooterMovil{
        margin-left: 35px;
    }
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){

  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
 
    // DESK
}
@media screen and (min-width: 930px) and (orientation: landscape) {
  
}
`

