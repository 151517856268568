import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CosmoContext } from "../context/CosmoContext";

import TopBar from "../components/home/TopBar";
import HomeComponent from "../components/home/HomeComponent";


const Dashboard = ({ DataHeader, windowSize,RutaFooter }) => {
  const location = useLocation();
  const { HandlerRoute, 
    ActualRouter, 
    HanlderSelectRouter,
    HandlerIsBackground, 
    RutaSeleccionada,
    HanlderSelectedFooter,
    
    
   } =
    useContext(CosmoContext);
  const iniciativas = DataHeader || [];
  const order = [
    "TODAS",
    "INICIATIVA DISRUPTIVA",
    "COMBO",
    "RÉPLICA",
    "EXHIBICIÓN",
    "ARTES",
    //"IMPORTACIONES",
    "INICIATIVA INCREMENTAL",
  ];
  const orderMovil = [
    "TODAS",
    "ARTES",
    "COMBO",
    "EXHIBICIÓN",
    "RÉPLICA",
    "INICIATIVA DISRUPTIVA",
    "INICIATIVA INCREMENTAL",
    //"IMPORTACIONES",
  ];

  const sortedIniciativas = order
    .map((nombre) =>
      iniciativas.find(
        (iniciativa) => iniciativa && iniciativa.nombre === nombre
      )
    )
    .filter(Boolean);

  const [iniciativasList, setIniciativasList] = useState(sortedIniciativas);

  const sortedIniciativasMovil = orderMovil
    .map((nombre) =>
      iniciativas.find(
        (iniciativa) => iniciativa && iniciativa.nombre === nombre
      )
    )
    .filter(Boolean);

  const [iniciativasListMovil, setIniciativasListMovil] = useState(
    sortedIniciativasMovil
  );

  const navigate = useNavigate();

  useEffect(() => {
    HandlerRoute(location.pathname);
    HanlderSelectedFooter(null)
    HandlerIsBackground(false)
  }, [ActualRouter]);

  const hanlderclick = () => {
    navigate("/ejemplo");
  };

  return (
    <div>
      <TopBar
        ListData={iniciativasList}
        ListDataMovil={iniciativasListMovil}
        windowSize={windowSize}
        HanlderSelectRouter={HanlderSelectRouter}
        RutaSeleccionada={RutaSeleccionada}
      ></TopBar>
      <HomeComponent
        windowSize={windowSize}
        HanlderSelectRouter={HanlderSelectRouter}
        RutaSeleccionada={RutaSeleccionada}
        RutaFooter={RutaFooter}
      ></HomeComponent>
    </div>
  );
};

export default Dashboard;
