import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
// styles
import { ModalMenuContainer } from "./MenuModalMobil";

import CloseImg from "../../../assets/images/menu/CloseSvg.svg";
import LogoImg from "../../../assets/images/logocosmos.png";
import ArrowDown from "../../../assets/images/menu/flechaabajo.svg";
import { useNavigate } from "react-router-dom";
import RutaIcon from "../../../assets/images/taskbar/Rutas.svg";
import MarcoIcon from "../../../assets/images/taskbar/Macro.svg";
import PoliticasIcon from "../../../assets/images/taskbar/Politicas.svg";
import ManualIcon from "../../../assets/images/taskbar/UserManual.svg";
import MartizIcon from "../../../assets/images/taskbar/Matriz.svg";
import SiteMapIcon from "../../../assets/images/taskbar/Sitemap.svg";
const customStyles = {
  content: {
    width: "80%",
    left: "0%",
    height: "100vh",
    top: "0%",
    transform: "translate(-0%, -0%) translateX(-100%)",
    transition: "transform 0.3s ease-in-out",
    borderTopRightRadius: "25px",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
};

const openModalStyles = {
  content: {
    ...customStyles.content,
    transform: "translate(-0%, -0%) translateX(0%)",
  },
  overlay: {
    ...customStyles.overlay,
    opacity: 1,
    zIndex: 99,
  },
};

const MenuMobilModal = ({
  modal,
  closemodal,
  dataRoute,
  openModalPrime,
  openModalMarco,
  openModalManual,
  openModalMatriz,
  HanlderSelectedFooter,
  HanlderSelectRouterMenu,
  openModalSite,
  DataHeader,
  RutaFooter,
  RutaSeleccionada,
  HanlderSelectRouter,
}) => {
  const [modalStyles, setModalStyles] = useState(customStyles);
  const [SelectedMarco, setSelectedMarco] = useState(false);
  const [SelectedRutas, setSelectedRutas] = useState(false);

  const navigate = useNavigate();
  const HandlerIniciar = ( ) =>{

          if (RutaFooter[0]?.nombre === "DEFINICIÓN ESTRATÉGICA") {
              navigate("/definicion-estrategica");
            } else if (RutaFooter[0]?.nombre === "IDEACIÓN Y PLAN DE NEGOCIOS") {
              navigate("/ideacion");
            } else if (RutaFooter[0]?.nombre === "DESARROLLO") {
              navigate("/desarrollo");
            } else if (RutaFooter[0]?.nombre === "FABRICACIÓN") {
              navigate("/fabricacion");
            } else if (RutaFooter[0]?.nombre === "SEGUIMIENTO") {
              navigate("/seguimiento");
            }
      
        
      }

  const handlerGo = (data) => {
    
    // HanlderSelectRouter(data?.nombre, data?.color);
    setTimeout(() => {
      closemodal()
      HandlerIniciar();
    }, 1000);
  };

  const HanlderShowMarco = () => {
    setSelectedRutas(false);
    if (SelectedMarco === true) {
      setSelectedMarco(false);
    } else {
      setSelectedMarco(true);
    }
  };
  const HanlderShowRutas = () => {
    setSelectedMarco(false);
    if (SelectedRutas === true) {
      setSelectedRutas(false);
    } else {
      setSelectedRutas(true);
    }
  };

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
      setModalStyles(openModalStyles);
    } else {
      document.body.style.overflow = "auto";
      setModalStyles(customStyles);
    }
  }, [modal]);

  const HanlderopenModal = (data) => {
    if (data === "MANUAL") {
      openModalManual();
    } else if (data === "MATRIZ") {
      openModalMatriz();
    } else if (data === "POLÍTICAS") {
    } else if (data === "SITE MAP") {
      openModalSite();
    } else if (data === "MARCO") {
      openModalMarco();
    }
    closemodal();
  };

  const HanlderNavigatreMenu = (data) => {
    if (data === "objetivo" || data === "tipos-de-proyectos") {
      HanlderSelectRouterMenu(data);
      navigate(`/${data}`);
    } else {
      switch (data) {
        case "DEFINICIÓN ESTRATÉGICA":
          navigate("/definicion-estrategica");
          closemodal();
          break;
        case "IDEACIÓN Y PLAN DE NEGOCIOS":
          navigate("/ideacion");
          closemodal();
          break;
        case "DESARROLLO":
          navigate("/desarrollo");
          closemodal();
          break;
        case "FABRICACIÓN":
          navigate("/fabricacion");
          closemodal();
          break;
        case "SEGUIMIENTO":
          navigate("/seguimiento");
          closemodal();
          break;
        default:
          break;
      }
      HanlderSelectedFooter(data);
    }

    closemodal();
  };

  return (
    <ReactModal
      isOpen={modal}
      contentLabel="onRequestClose Example"
      onRequestClose={closemodal}
      shouldCloseOnOverlayClick={false}
      style={modalStyles}
      ariaHideApp={false}
    >
      <ModalMenuContainer
        style={modal ? { display: "block" } : { display: "none" }}
      >
        <div className="ContainerActionButtonsMenuMobil">
          <div
            onClick={() => closemodal()}
            className="ContainerCloseActionButtonMobil"
          >
            <img src={CloseImg} alt="CloseImg" />
          </div>
          <div>
            <img width={120} src={LogoImg} alt="LogoCosmo" />
          </div>
        </div>

        <div className="ContainerItemsMenuMobil">
          <div
            onClick={() => HanlderNavigatreMenu("objetivo")}
            className="ContainerItemMenuMobil"
          >
            <p>OBJETIVO DEL PROCESO</p>
          </div>
          <div
            onClick={() => HanlderNavigatreMenu("tipos-de-proyectos")}
            className="ContainerItemMenuMobil"
          >
            <p>TIPOS DE PROYECTO</p>
          </div>

          <div className="ContainerItemMenuMobil">
            <div
              className="ContainerItemMenuMobilDown"
              onClick={() => HanlderShowMarco()}
            >
              <div>
                <p>MACROPROCESO</p>
              </div>
              <div>
                <img width={18} src={ArrowDown} alt="flechaabajo" srcset="" />
              </div>
            </div>
          </div>
          <div
            style={
              SelectedMarco
                ? { display: "block", height: "auto" }
                : { display: "none", height: 0 }
            }
            className="ContainerDropDown"
          >
            <ul>
              {dataRoute.ruta?.map((r) => {
                return (
                  <li
                    onClick={() => HanlderNavigatreMenu(r.nombre)}
                    style={{ cursor: "pointer" }}
                  >
                    {r.nombre}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="ContainerItemMenuMobil">
            <div
              className="ContainerItemMenuMobilDown"
              onClick={() => HanlderShowRutas()}
            >
              <div className="ContainerIconsMenuDesk">
                <div>
                  <img width={25} src={RutaIcon} alt="flechaabajo" srcset="" />
                </div>
                <p>RUTAS</p>
              </div>
              <div>
                <img width={18} src={ArrowDown} alt="flechaabajo" srcset="" />
              </div>
            </div>
          </div>
          <div
            style={
              SelectedRutas
                ? { display: "block", height: "auto" }
                : { display: "none", height: 0 }
            }
            className="ContainerDropDown"
          >
            <ul>
              {dataRoute.iniciativas?.map((r) => {
                return <li
                onClick={() => handlerGo(r)}
                >{r.nombre}</li>;
              })}
            </ul>
          </div>
          <div className="ContainerItemMenuMobil">
            <div
              className="ContainerIconsMenuDesk"
              onClick={() => HanlderopenModal("MARCO")}
            >
              <div>
                <img width={25} src={MarcoIcon} alt="flechaabajo" srcset="" />
              </div>
              <p>MACRO</p>
            </div>
          </div>
          <div
            className="ContainerItemMenuMobil"
            onClick={() => HanlderopenModal("MANUAL")}
          >
            <div className="ContainerIconsMenuDesk">
              <div>
                <img width={25} src={ManualIcon} alt="flechaabajo" srcset="" />
              </div>
              <p>MANUAL</p>
            </div>
          </div>
          <div className="ContainerItemMenuMobil">
            <div
              className="ContainerIconsMenuDesk"
              onClick={() => HanlderopenModal("MATRIZ")}
            >
              <div>
                <img width={25} src={MartizIcon} alt="flechaabajo" srcset="" />
              </div>
              <p style={{ textTransform: "uppercase" }}>Matriz de CoRCIG</p>
            </div>
          </div>
          <div className="ContainerItemMenuMobil">
            <div
              className="ContainerIconsMenuDesk"
              onClick={() => HanlderopenModal("POLÍTICAS")}
            >
              <div>
                <img
                  width={25}
                  src={PoliticasIcon}
                  alt="flechaabajo"
                  srcset=""
                />
              </div>
              <p>POLÍTICAS</p>
            </div>
          </div>
          <div className="ContainerItemMenuMobil">
            <div
              className="ContainerIconsMenuDesk"
              onClick={() => HanlderopenModal("SITE MAP")}
            >
              <div>
                <img width={25} src={SiteMapIcon} alt="flechaabajo" srcset="" />
              </div>
              <p>SITE MAP</p>
            </div>
          </div>

          {/* <div className="ContainerItemMenuMobil">
            <div className="ContainerIconsMenuDesk">
              <div>
                <img width={25} src={SiteMapIcon} alt="flechaabajo" srcset="" />
              </div>
              <p>Cerrar Session</p>
            </div>
          </div> */}
        </div>
      </ModalMenuContainer>
    </ReactModal>
  );
};

export default MenuMobilModal;
